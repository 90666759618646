import produce from "immer"
import get from "lodash/get"
import set from "lodash/set"
import moment from "moment"
import generateShortID from "../../../../../utils"
import {
  SET_ENABLE_TIME_BASED,
  SET_ENABLE_TIME_BASED_TYPE,
  SET_ENABLE_TOUCH,
  SET_ENABLE_TOUCH_VALUE,
  SET_TERMINATE_INFO_ATTRIBUTES_USER,
  SET_TERMINATE_INFO_ENABLE_ATTRIBUTES,
  SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE,
  SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS,
  SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
  SET_TIME_BASED_DAYS,
} from "../../../components/walkthroughs/components/design/constants"
import {
  ADD_CUSTOM_FONT,
  ADD_NEW_DATATYPE,
  ADD_NEW_OPERATOR,
  ADD_NEW_PROPERTY,
  ADD_NEW_PROPERTY_IN_AUDIENCE,
  ADD_NEW_PROPERTY_VALUE,
  ADD_STEP_TO_VARIANT,
  ADD_VARIANT,
  APP_PLATFORMS,
  DEFAULT_GOAL_EVENT_STATE,
  DEFAULT_NEW_SINGLE_CONDITIONS,
  DELETE_STEP_FROM_VARIANT,
  DELETE_VARIANT,
  ENABLE_CONDITIONS,
  NEW_ADD_EVENT,
  NEW_ADD_INNER_EVENT,
  NEW_DELETE_ADDITIONAL_INFO,
  NEW_DELETE_PROPERTY,
  NEW_EVENT_COUNT,
  NEW_EVENT_OPERATOR,
  NEW_EVENT_TYPE,
  NEW_REMOVE_EVENT,
  NEW_REMOVE_INNER_EVENT,
  ON_COPY_STEP_VARIANT,
  REMOVE_AUDIENCE_ATTRIBUTE,
  REMOVE_CUSTOM_FONT,
  REMOVE_TEST_DEVICE,
  SCHEDULING_ENUM,
  SET_ADD_CONDITION,
  SET_APP_SCREENS,
  SET_APP_SCREEN_NAMES,
  SET_AUDIENCE_ATTRIBUTE,
  SET_AUDIENCE_ATTRIBUTES_ARRAY,
  SET_AUDIENCE_TYPE,
  SET_AUDIENCE_USER_ATTR,
  SET_AUTO_DISMISS_DURATION,
  SET_AUTO_DISMISS_ENABLED,
  SET_AUTO_PUBLISH_WINNER,
  SET_CAMPAIGN_PUBLISH_CG,
  SET_CLIENT_EVENTS,
  SET_COHORTS,
  SET_COHORT_ID,
  SET_CONDITIONS_TYPE,
  SET_CONFIG,
  SET_CONTEXT_TYPE,
  SET_CONTROL_GROUP,
  SET_COUNT_LIMIT_ENABLED,
  SET_CURRENT_VARIANT_STEP,
  SET_DAYS_IN_WEEK,
  SET_DAYS_LIMITS_ENABLED,
  SET_DEFAULT_TEST_DEVICES,
  SET_DEMO_UPSERT_CONDITION,
  SET_DIDNOT_DO_EVENTS,
  SET_DYNAMIC_COHORTS,
  SET_DYNAMIC_COHORT_ID,
  SET_ENABLE_CONDITIONS,
  SET_ENABLE_CONDITIONS_V2,
  SET_ENABLE_TESTING,
  SET_EVENTS,
  SET_EXCLUDE_ANONYMOUS_USERS,
  SET_EXCLUDE_COHORT_ID,
  SET_EXCLUDE_COHORT_TOGGLE,
  SET_EXCLUDE_EVENTS_TOGGLE,
  SET_EXCLUDE_TIME_LIMITS_ENABLE,
  SET_EXCLUDE_TIME_LIMITS_END,
  SET_EXCLUDE_TIME_LIMITS_START,
  SET_EXPERIMENT,
  SET_FREQ_COUNT,
  SET_FREQ_DAYS,
  SET_FREQ_DAYS_LIMIT,
  SET_FREQ_LIMITS_ENABLED,
  SET_FREQ_SESSION_LIMIT,
  SET_FREQ_VALIDITY,
  SET_GLOBAL_THEME,
  SET_GOAL_EVENT,
  SET_GOAL_MET_CHECK,
  SET_IS_DAYS_IN_WEEK_ENABLED,
  SET_IS_USER_TIMEZONE,
  SET_META_CONFIG_TYPE,
  SET_META_DESC,
  SET_META_IS_MWEB,
  SET_META_NAME,
  SET_META_PLATFORM,
  SET_META_TAGS,
  SET_META_TYPE,
  SET_NEW_ENABLE_ACROSS_SESSION,
  SET_NEW_ENABLE_TIME_INNER,
  SET_NEW_ENABLE_TIME_OUTER,
  SET_NEW_EVENT_CATEGORY,
  SET_NEW_EVENT_DETAILS,
  SET_NEW_INNER_COMBINE_OPERATOR,
  SET_NEW_INNER_COMBINE_OPERATOR_FRONTEND,
  SET_NEW_NUMBER_OF_ACROSS_SESSION,
  SET_NEW_ORDER_SEQUENCE,
  SET_NEW_ORDER_SEQUENCE_PARENT,
  SET_NEW_OUTER_COMBINE_OPERATOR,
  SET_NEW_OUTER_COMBINE_OPERATOR_FRONTEND,
  SET_NEW_TIME_INNER,
  SET_NEW_TIME_OUTER,
  SET_NO_CONTEXT_ENABLED,
  SET_NTH_EVENT,
  SET_OPERATOR_AND_SEQUENCE_ENABLED,
  SET_PRIMARY_GOAL_EVENT,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_ELEMENT,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR,
  SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES,
  SET_PRIMARY_GOAL_EVENT_OPERATOR,
  SET_PRIMARY_GOAL_EVENT_REMOVE_ATTRIBUTE_VALUES,
  SET_PRIMARY_GOAL_EVENT_TYPE,
  SET_PRIMARY_GOAL_EVENT_VALUE,
  SET_PRIORITY,
  SET_PUBLISH_END_DATE,
  SET_PUBLISH_SHOW_AT_SPECIFIC_TIME,
  SET_PUBLISH_START_DATE,
  SET_PUBLISH_TIME_ENABLED,
  SET_PUBLISH_TIME_LIMITS_END,
  SET_PUBLISH_TIME_LIMITS_START,
  SET_RECUR_TYPE,
  SET_REMOVE_CONDITION,
  SET_REPETITION_GAP_ENABLED,
  SET_REPETITION_GAP_UNIT,
  SET_REPETITION_GAP_VALUE,
  SET_RETAINED_DAY_FROM,
  SET_RETAINED_DAY_TO,
  SET_ROLL_INTERVAL,
  SET_ROLL_RECURR,
  SET_ROLL_STEP,
  SET_SCHEDULING_INSTANCE,
  SET_SCHEDULING_TIME,
  SET_SEGMENT,
  SET_SEGMENTS,
  SET_SELECTED_TEST_DEVICE,
  SET_SELECTED_TEST_VARIANT,
  SET_SESSION_LIMIT_FROM,
  SET_SESSION_LIMIT_TO,
  SET_SNACKBAR_ERROR_MESSAGE,
  SET_SURVEY_DELAY,
  SET_SURVEY_TYPE,
  SET_TARGET_ANONYMOUS_USERS_ONLY,
  SET_TARGET_PERCENTAGE,
  SET_TEST_DEVICES,
  SET_TEST_END_DATE,
  SET_TEST_SHOW_AT_SPECIFIC_TIME,
  SET_TEST_START_DATE,
  SET_TEST_TIME_LIMITS_END,
  SET_TEST_TIME_LIMITS_START,
  SET_TIME_LIMITS_END,
  SET_TIME_LIMITS_START,
  SET_TIME_SPENT,
  SET_TOGGLE_APP_VERSION_FILTER,
  SET_TOGGLE_EXCLUDE_TIME_LIMITS_ENABLED,
  SET_TOGGLE_RECURR,
  SET_TOGGLE_RETAINED_DAY,
  SET_TOGGLE_ROLL,
  SET_TOGGLE_SESSION_ATTRIBUTES,
  SET_TOGGLE_SESSION_LIMITS,
  SET_TOGGLE_TIME_LIMITS_ENABLED,
  SET_TOGGLE_USER_ATTRIBUTES,
  SET_TOGGLE_USER_TIME_ZONE_ENABLED,
  SET_UI,
  SET_UIS,
  SET_UNTIL_CAMPAIGN_PAUSED_ENABLED,
  SET_UPDATE_CONDITION,
  SET_VALIDITY_END,
  SET_VALIDITY_START,
  SET_VARIANT_PERCENTAGE,
  SET_VERSION_OPERATOR,
  SET_VERSION_VALUE,
  TARGET_AUDIENCE_ENUM,
  UPDATE_TEST_DEVICES,
} from "./constants"

export const getTimeLimitsEnabledKey = (platform) => {
  if (platform === APP_PLATFORMS.ios) {
    return "time_limits_in_day"
  } else {
    return "at_specific_time"
  }
}

const DEFAULT_SCHEDULING_STATE = {
  schedule_date: moment(moment().toISOString())
    .utcOffset("+05:30")
    .format("YYYY-MM-DD"),
  schedule_time: moment(moment().toISOString())
    .utcOffset("+05:30")
    .format("HH:mm"),
  schedule: SCHEDULING_ENUM.NOW,
  isRecurEnabled: false,
  rollSegment: false,
  rollInterval: 1,
  recur: "DAY",
  step: 1,
}

export const initial_state = {
  config: {
    surveyType: "legacy",
    audience: {
      audience_type: "ALL",
      only_anonymous: false,
      exclude_anonymous: false,
      attributes: { user: [], session: [] },
      limited: {},
      cohort_id: "",
      segment_id: "",
      end_time: moment().utc().endOf("day").toISOString().slice(0, 23) + "Z",
      start_time:
        moment()
          .subtract("days", 15)
          .utc()
          .startOf("day")
          .toISOString()
          .slice(0, 23) + "Z",
    },
    c_type: 0,
    no_context_enabled: false,
    conditions_type: "legacy",
    enable_conditions: true,
    enable_conditions_v2: false,
    conditions: [],
    conditions_v2: [],
    sequence_type: "unordered",
    across_sessions: false,
    no_of_sessions: 2,
    sequence_enabled: false,
    pre_cs: [],
    pre_cs_seq_enabled: false,
    terminate_info: {
      auto_dismiss: false,
      duration: 5000,
      conditions: [],
      enable_time_based: false,
      time_based: {
        type: "relative",
        duration_seconds: 10,
        days: 1,
      },
      enable_goal_events: false,
      goal_events: {
        sequence_enabled: false,
        events: [],
      },
      enable_touch: false,
      touch: {
        target_view: 1,
      },
      enable_attributes: false,
      attributes: {
        session: [],
        user: [],
      },
    },
    enabled: true,
    cg_percentage: 3,
    experiment: {
      publishSettings: {
        CG: 3,
        time_settings: { time_limits: {} },
      },
    },
    frequency: {
      count: 1,
      time_interval: 1000,
      validity: "OVERALL",
      DSLimits: false,
      enable_repetition_gap: false,
      repetition_gap: {
        unit: "day",
        val: "1",
      },
      enable_days_limit: false,
      days_limit: {
        days: 1,
        limit: 1,
      },
      enable_session_limit: false,
      session_count: 1,
      enable_count_limit: false,
    },
    meta: {
      name: "",
      description: "",
      config_type: "walkthrough",
      type: "WALKTHROUGH",
      tags: [],
      platform: "android",
      v2: true,
      isExperiment: false,
      version: 2,
      is_mweb: false,
    },
    mutation_id: null,
    overall_cfg: {
      ret_day: {},
      events: [],
      session: {},
      exclude_cohort: {
        cohort_id: "",
      },
      hasExcludeCohort: false,
      hasEvents: false,
      toggleRetDay: false,
      toggleSession: false,
    },
    published: false,
    scheduling: DEFAULT_SCHEDULING_STATE,
    goal_met_check: false,
    primary_goal: DEFAULT_GOAL_EVENT_STATE.primary_goal,
    testing: { enabled: false, devices: [] },
    time_limits: { start_time: "10:00", end_time: "20:30" },
    time_limits_in_day: false,
    at_specific_time: false,
    time_excludes_in_day: false,
    time_excludes: { start_time: "10:00", end_time: "10:00" },
    is_user_timezone: true,
    validity: {
      is_publish_time: true,
      until_campaign_paused: true,
      start_date: moment().toISOString(),
      end_date: moment().add("days", 7).toISOString(),
      is_days_in_week_enabled: false,
      days_in_week: [1, 2, 3, 4, 5, 6, 7],
    },
    theme: {
      custom_fonts: [],
      global_themes: {},
    },
    ui: {},
    uis: [],
  },
  uisToDelete: [],
  app_events: [],
  app_screens: [],
  app_screen_names: [],
  cohorts: [],
  dynamic_cohorts: [],
  segments: [],
  client_events: [],
  test_devices: [],
  snackbarMessage: null,
}

export const reducer = produce((draft, action) => {
  let attrs

  if (draft.config.experiment) {
    if (!draft.config.experiment.publishSettings) {
      draft.config.experiment.publishSettings = {
        CG: 3,
        time_settings: {
          time_limits: {},
        },
      }
    }
  }

  switch (action.type) {
    case SET_CONFIG:
      draft.config = action.config
      break
    case SET_SURVEY_TYPE:
      draft.config.surveyType = action.value
      break
    case SET_CONTROL_GROUP:
      draft.config.cg_percentage = 0
      if (draft.config.experiment) {
        draft.config.experiment.publishSettings.CG = 0
      }
      break
    case SET_NO_CONTEXT_ENABLED:
      draft.config.no_context_enabled = action.enabled
      break
    case SET_EVENTS:
      draft.app_events = action.events
      break
    case SET_APP_SCREEN_NAMES:
      draft.app_screen_names = action.screens
      break
    case SET_APP_SCREENS:
      draft.app_screens = action.screens
      break
    case SET_META_TYPE:
      draft.config.meta.type = action.value
      break
    case SET_META_CONFIG_TYPE: {
      let config_type = "walkthrough"
      let type = "walkthrough"
      switch (action.config_type) {
        case "surveys":
        case "survey":
          config_type = "survey"
          type = "SURVEY"
          break
        case "onboarding":
          config_type = "onboarding"
          type = "ONBOARDING"
          break
        default:
          break
      }

      if (draft.config.meta.config_type !== config_type) {
        draft.config.ui = {}
        draft.config.uis = []
      }

      draft.config.meta.config_type = config_type
      draft.config.meta.type = type
      break
    }
    case SET_META_NAME:
      draft.config.meta.name = action.name
      break
    case SET_META_TAGS:
      draft.config.meta.tags = action.tags
      break
    case SET_META_DESC:
      draft.config.meta.description = action.desc
      break
    case SET_META_PLATFORM:
      draft.config.meta.platform = action.platform
      break
    case SET_META_IS_MWEB:
      draft.config.meta.is_mweb = action.is_mweb
      break
    case ADD_CUSTOM_FONT:
      draft.config.theme.custom_fonts.push(action.payload)
      break
    case REMOVE_CUSTOM_FONT:
      draft.config.theme.custom_fonts = draft.config.theme.custom_fonts.filter(
        (font) => font?.name !== action.payload,
      )
      break

    case SET_GLOBAL_THEME:
      if (!draft.config.hasOwnProperty("theme")) {
        draft.config.theme = { global_themes: {} }
      }
      draft.config.theme.global_themes = action.payload
      break
    case SET_AUDIENCE_TYPE:
      draft.config.audience.audience_type = action.audience_type
      draft.config.scheduling.isRecurEnabled = false
      switch (action.audience_type) {
        case TARGET_AUDIENCE_ENUM.ALL.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.rollSegment = false
          break
        case TARGET_AUDIENCE_ENUM.SEGMENT.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          break
        case TARGET_AUDIENCE_ENUM.COHORT.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.isRecurEnabled = false
          draft.config.scheduling.rollSegment = false
          break
        case TARGET_AUDIENCE_ENUM.NEW_USERS.key:
          draft.config.overall_cfg.ret_day.from = 0
          draft.config.overall_cfg.ret_day.to = 0
          break
        case TARGET_AUDIENCE_ENUM.DYNAMIC_COHORT.key:
          draft.config.audience.segment_id = ""
          draft.config.audience.cohort_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.isRecurEnabled = false
          draft.config.scheduling.rollSegment = false
          break
        default:
          break
      }
      if (action.audience_type !== TARGET_AUDIENCE_ENUM.NEW_USERS.key) {
        draft.config.audience.only_anonymous = false
        draft.config.audience.exclude_anonymous = false
      }
      break
    case SET_AUDIENCE_USER_ATTR:
      draft.config.audience.attributes.user = action.user
      break
    case SET_SEGMENT:
      // Clear the Cohort ID
      draft.config.audience.cohort_id = ""
      draft.config.audience.segment_id = action.segment_id
      if (action.start_time) {
        draft.config.audience.start_time = action.start_time
      }
      if (action.end_time) {
        draft.config.audience.end_time = action.end_time
      }
      break
    case SET_COHORT_ID:
      draft.config.audience.segment_id = ""
      draft.config.audience.cohort_id = action.cohort_id
      break
    case SET_DYNAMIC_COHORT_ID:
      draft.config.audience.segment_id = ""
      draft.config.audience.cohort_id = action.dynamic_cohort_id
      break
    case SET_FREQ_COUNT:
      draft.config.frequency.count = action.count
      break
    case SET_FREQ_DAYS:
      if (!draft.config.frequency.days_limit) {
        draft.config.frequency.days_limit = { limit: 1 }
      }
      draft.config.frequency.days_limit.days = action.value
      break
    case SET_FREQ_DAYS_LIMIT:
      if (!draft.config.frequency.days_limit) {
        draft.config.frequency.days_limit = { days: 1 }
      }
      draft.config.frequency.days_limit.limit = action.value
      break
    case SET_DAYS_LIMITS_ENABLED:
      draft.config.frequency.enable_days_limit = action.value
      break
    case SET_FREQ_LIMITS_ENABLED:
      if (!action.enabled) {
        const { day_lmt, ses_lmt, ...freq } = draft.config.frequency
        draft.config.frequency = { ...freq }
      } else if (!draft.config.frequency.ses_lmt) {
        draft.config.frequency.ses_lmt = 1
        draft.config.frequency.session_count = 1
      }
      draft.config.frequency.DSLimits = action.enabled
      draft.config.frequency.enable_session_limit = action.enabled
      break
    case SET_COUNT_LIMIT_ENABLED:
      draft.config.frequency.enable_count_limit = action.value
      break
    case SET_FREQ_SESSION_LIMIT:
      draft.config.frequency.ses_lmt = action.value
      draft.config.frequency.session_count = action.value
      break
    case SET_FREQ_VALIDITY:
      draft.config.frequency.validity = action.validity
      break
    case SET_RECUR_TYPE:
      draft.config.frequency.recur_type = action.value
      break
    case SET_REPETITION_GAP_ENABLED:
      draft.config.frequency.enable_repetition_gap = action.value
      break
    case SET_REPETITION_GAP_UNIT:
      if (!draft.config.frequency.repetition_gap) {
        draft.config.frequency.repetition_gap = { val: 1 }
      }
      draft.config.frequency.repetition_gap.unit = action.value
      break
    case SET_REPETITION_GAP_VALUE:
      if (!draft.config.frequency.repetition_gap) {
        draft.config.frequency.repetition_gap = { unit: "day" }
      }
      draft.config.frequency.repetition_gap.val = action.value
      break
    case SET_RETAINED_DAY_TO:
      draft.config.overall_cfg.ret_day.to = action.value
      break
    case SET_RETAINED_DAY_FROM:
      draft.config.overall_cfg.ret_day.from = action.value
      break
    case SET_DIDNOT_DO_EVENTS:
      draft.config.overall_cfg.events = action.events
      break
    case SET_SESSION_LIMIT_FROM:
      draft.config.overall_cfg.session.from = action.value
      break
    case SET_SESSION_LIMIT_TO:
      draft.config.overall_cfg.session.to = action.value
      break
    case SET_TOGGLE_RETAINED_DAY:
      draft.config.overall_cfg.toggleRetDay = action.enabled
      if (action.enabled === false) {
        draft.config.overall_cfg.ret_day = {}
      } else {
        if (!draft.config.overall_cfg.ret_day)
          draft.config.overall_cfg.ret_day = {}
        draft.config.overall_cfg.ret_day.from = action?.from
        draft.config.overall_cfg.ret_day.to = action?.to
      }
      break
    case SET_TOGGLE_SESSION_LIMITS:
      draft.config.overall_cfg.toggleSession = action.enabled
      if (action.enabled === false) {
        draft.config.overall_cfg.session = {}
      } else {
        if (!draft.config.overall_cfg.session)
          draft.config.overall_cfg.session = {}
        draft.config.overall_cfg.session.from = action?.from
        draft.config.overall_cfg.session.to = action?.to
      }
      break
    case SET_TOGGLE_RECURR:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.isRecurEnabled = action.enabled
      draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
      break
    case SET_TOGGLE_ROLL:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.rollSegment = action.enabled
      break
    case SET_ROLL_INTERVAL:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.rollInterval = action.value
      break
    case SET_ROLL_RECURR:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.recur = action.value
      break
    case SET_ROLL_STEP:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.step = action.value
      break
    case SET_AUTO_DISMISS_DURATION:
      draft.config.terminate_info.duration = action.value
      break
    case SET_AUTO_DISMISS_ENABLED:
      draft.config.terminate_info.auto_dismiss = action.value
      break
    case SET_GOAL_EVENT:
      draft.config.goal_event = action.event
      if (!action.event) {
        draft.config.terminate_info.enable_goal_events = false
      }
      break
    case SET_PRIMARY_GOAL_EVENT_TYPE:
      if (!draft.config?.primary_goal) {
        draft.config.primary_goal = DEFAULT_GOAL_EVENT_STATE.primary_goal
        draft.config.goal_event = ""
      }
      if (draft.config.primary_goal.type !== action.eventType) {
        draft.config.goal_event = ""
        draft.config.primary_goal.name = ""
        draft.config.primary_goal.operator = ""
        draft.config.primary_goal.value = []
        draft.config.primary_goal.attributes = []
        if (action.eventType !== "event") {
          draft.config.primary_goal.attributes.push({
            name: "",
            operator: "",
            value: [],
          })
        }
      }
      draft.config.primary_goal.type = action.eventType

      break
    case SET_PRIMARY_GOAL_EVENT:
      if (!draft.config?.primary_goal) {
        draft.config.primary_goal = DEFAULT_GOAL_EVENT_STATE.primary_goal
      }
      if (action.event === "") {
        draft.config.primary_goal.attributes = []
      }
      draft.config.primary_goal.name = action.event
      break
    case SET_PRIMARY_GOAL_EVENT_OPERATOR:
      draft.config.primary_goal.operator = action.operator
      break
    case SET_PRIMARY_GOAL_EVENT_VALUE:
      draft.config.primary_goal.value = action.value
      break
    case SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_ELEMENT:
      draft.config.primary_goal.attributes.push({
        name: "",
        operator: "",
        value: [],
        method: "filter",
      })
      break
    case SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE:
      draft.config.primary_goal.attributes[action.index] = {
        name: "",
        operator: "",
        value: [],
        method: "filter",
      }
      draft.config.primary_goal.attributes[action.index].name = action.value
      break
    case SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_OPERATOR:
      draft.config.primary_goal.attributes[action.index].operator = action.value
      break
    case SET_PRIMARY_GOAL_EVENT_ADD_ATTRIBUTE_VALUES:
      draft.config.primary_goal.attributes[action.index].value = action.value
      break
    case SET_PRIMARY_GOAL_EVENT_REMOVE_ATTRIBUTE_VALUES:
      if (!draft.config.primary_goal.attributes.length < action.index) {
        const attributes = draft.config.primary_goal.attributes
        draft.config.primary_goal.attributes = attributes.filter(
          (item, index) => index !== action.index,
        )
      } else {
        draft.config.primary_goal.attributes = []
      }
      break
    case SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS:
      draft.config.terminate_info.enable_goal_events = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_ENABLE_TIME_BASED:
      draft.config.terminate_info.enable_time_based = action.enabled
      if (!action.enabled) {
        draft.config.terminate_info.enable_time_based = false
      }
      break
    case SET_ENABLE_TIME_BASED_TYPE:
      draft.config.terminate_info.enable_time_based = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TIME_BASED_DAYS:
      draft.config.terminate_info.time_based = {
        ...(draft.config.terminate_info?.time_based ?? {}),
        days: action.value,
      }
      break
    case SET_ENABLE_TOUCH_VALUE:
      draft.config.terminate_info.enable_touch = action.enabled
      if (!action.enabled) {
        draft.config.terminate_info.enable_touch = false
      }
      break
    case SET_ENABLE_TOUCH:
      draft.config.terminate_info.enable_touch = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE:
      draft.config.terminate_info.enable_attributes = action.enabled
      if (!action.enabled) {
        draft.config.terminate_info.enable_attributes = false
      }
      break
    case SET_TERMINATE_INFO_ENABLE_ATTRIBUTES:
      draft.config.terminate_info.enable_attributes = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TERMINATE_INFO_ATTRIBUTES_USER:
      draft.config.terminate_info.attributes = {
        ...(draft.config.terminate_info?.attributes ?? {}),
        user: action.value ? [action.value] : [],
      }
      break
    case SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS:
      draft.config.terminate_info.goal_events = {
        ...(draft.config.terminate_info?.goal_events ?? {}),
        events: [action.value],
      }
      break
    case SET_TEST_SHOW_AT_SPECIFIC_TIME:
    case SET_TOGGLE_TIME_LIMITS_ENABLED:
      draft.config[getTimeLimitsEnabledKey(draft.config.meta.platform)] =
        action.enabled
      break
    case SET_TOGGLE_EXCLUDE_TIME_LIMITS_ENABLED:
      draft.config.time_excludes_in_day = action.enabled
      break
    case SET_TOGGLE_USER_TIME_ZONE_ENABLED:
      draft.config.is_user_timezone = action.enabled
      break
    case SET_TEST_TIME_LIMITS_END:
    case SET_TIME_LIMITS_END:
      draft.config.time_limits.end_time = action.end_hour_min
      break
    case SET_EXCLUDE_TIME_LIMITS_ENABLE:
      draft.config.time_excludes_in_day = action.enabled
      if (action.isOld) {
        draft.config["time_excludes"] = {
          start_time: "10:00",
          end_time: "10:00",
        }
      }
      break
    case SET_EXCLUDE_TIME_LIMITS_END:
      draft.config.time_excludes.end_time = action.end_hour_min
      break
    case SET_TEST_TIME_LIMITS_START:
    case SET_TIME_LIMITS_START:
      draft.config.time_limits.start_time = action.start_hour_min
      break
    case SET_EXCLUDE_TIME_LIMITS_START:
      draft.config.time_excludes.start_time = action.start_hour_min
      break
    case SET_TEST_START_DATE:
    case SET_VALIDITY_START:
      draft.config.validity.start_date = action.date_time
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.schedule_date = action.date
      draft.config.scheduling.schedule_time = action.time
      // draft.config.validity.end_date =
      //   draft.config.validity.end_date.slice(
      //     0,
      //     draft.config.validity.end_date.indexOf("T") + 1
      //   ) + action.date_time.slice(action.date_time.indexOf("T") + 1);
      break
    case SET_DAYS_IN_WEEK:
      draft.config.validity.days_in_week = action.days_in_week
      break
    case SET_IS_DAYS_IN_WEEK_ENABLED:
      draft.config.validity.is_days_in_week_enabled =
        action.is_days_in_week_enabled
      draft.config.validity.days_in_week = [1, 2, 3, 4, 5, 6, 7]
      break
    case SET_PUBLISH_TIME_ENABLED:
      draft.config.validity.is_publish_time = action.is_publish_time
      break
    case SET_UNTIL_CAMPAIGN_PAUSED_ENABLED:
      draft.config.validity.until_campaign_paused = action.until_campaign_paused
      break
    case SET_IS_USER_TIMEZONE:
      draft.config.is_user_timezone = action.is_user_timezone
      break
    case SET_SCHEDULING_TIME:
      draft.config.scheduling.schedule_time = action.time
      break
    case SET_TEST_END_DATE:
    case SET_VALIDITY_END:
      draft.config.validity.end_date = action.date_time
      break
    case SET_TEST_DEVICES:
      draft.config.testing.devices = action.devices
      draft.config.testing.enabled = action.devices.length > 0
      break
    case SET_ENABLE_TESTING:
      draft.config.testing.enabled = action.enabled
      break
    case UPDATE_TEST_DEVICES:
      draft.config.testing.devices = action.devices
      break
    case REMOVE_TEST_DEVICE:
      if (draft.config.testing.devices.includes(action.device)) {
        const devices = draft.config.testing.devices
        draft.config.testing.devices = devices.filter(
          (d) => d !== action.device,
        )
      }
      break
    case SET_PRIORITY:
      draft.config.prio = action.value
      break
    case SET_TIME_SPENT:
    case SET_NTH_EVENT:
      draft.config.conditions = [...action.conditions]
      break
    case SET_CONTEXT_TYPE: {
      draft.config.c_type = action.index
      break
    }
    case SET_GOAL_MET_CHECK:
      draft.config.goal_met_check = !draft?.config?.goal_met_check
      break
    case SET_CONDITIONS_TYPE:
      draft.config.conditions_type = action.value
      if (
        action.value === "latest" &&
        !draft.config.hasOwnProperty("conditions_v2")
      ) {
        draft.config.conditions_v2 = []
      }
      break
    case SET_ENABLE_CONDITIONS:
      draft.config.enable_conditions = action.checked
      if (!action.checked) draft.config.conditions = []
      break
    case SET_ENABLE_CONDITIONS_V2:
      draft.config.enable_conditions_v2 = action.checked
      break
    case ENABLE_CONDITIONS:
      if (action?.value) {
        draft.config.pre_cs = []
        draft.config.terminate_info.conditions = []
        draft.config.pre_cs_seq_enabled = false
        draft.config.terminate_info.sequence_enabled = false
        draft.config.goal_met_check = false
      } else {
        delete draft?.config?.pre_cs
        delete draft?.config?.terminate_info?.conditions
        delete draft?.config?.pre_cs_seq_enabled
        delete draft?.config?.terminate_info?.sequence_enabled
        delete draft?.config?.goal_met_check
      }
      break
    case SET_ADD_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      if (action?.is_goal_event) cs.unshift(action?.condition)
      else cs.push(action?.condition)
      break
    }
    case SET_DEMO_UPSERT_CONDITION:
      draft.config.conditions = [action.condition]
      break
    case SET_REMOVE_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      const sequence_enabled = get(
        draft?.config,
        action?.conditions_type?.se,
        false,
      )
      const updatedConditions = [
        ...(cs?.length ? cs.slice(0, action.index) : []),
        ...(cs?.length ? cs.slice(action.index + 1, cs?.length) : []),
      ]
      cs.splice(
        0,
        cs.length,
        ...(updatedConditions?.length
          ? updatedConditions.map((condition, index) => ({
              ...condition,
              sequence: sequence_enabled ? index + 1 : -1,
            }))
          : []),
      )
      break
    }
    case SET_UPDATE_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      cs[action.index] = { ...action?.condition }
      break
    }
    case SET_OPERATOR_AND_SEQUENCE_ENABLED: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      cs.splice(
        0,
        cs?.length,
        ...(cs?.length
          ? cs.map((condition, index) => ({
              ...condition,
              combine_operator: action?.operator,
              sequence: action?.enabled ? index + 1 : -1,
            }))
          : []),
      )
      set(draft?.config, action?.conditions_type?.se, action?.enabled)
      break
    }
    // conditions V2 actions
    case NEW_ADD_EVENT: {
      let combineOperator = "OR"
      let combineOperatorFrontend = "OR"
      if (draft.config.conditions_v2.length === 0) {
        draft.config.sequence_type = "unordered"
      }
      if (draft.config.conditions_v2.length > 0) {
        combineOperator = draft.config.conditions_v2[0].combine_operator
        combineOperatorFrontend =
          draft.config.conditions_v2[0].combine_operator_frontend
      }
      draft.config.conditions_v2.push({
        ...DEFAULT_NEW_SINGLE_CONDITIONS,
        combine_operator: combineOperator,
        combine_operator_frontend: combineOperatorFrontend,
        _id: generateShortID(),
      })
      break
    }
    case NEW_REMOVE_EVENT:
      draft.config.conditions_v2.splice(action.outerIndex, 1)
      break
    case NEW_ADD_INNER_EVENT:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        const tempCondition = {
          ...draft.config.conditions_v2[action.outerIndex],
        }
        tempCondition.combine_operator = "OR"
        tempCondition.combine_operator_frontend = "OR"
        draft.config.conditions_v2[action.outerIndex]._id = generateShortID()
        draft.config.conditions_v2[action.outerIndex].condition_type = "group"
        draft.config.conditions_v2[action.outerIndex].conditions_v2 = [
          tempCondition,
          {
            ...DEFAULT_NEW_SINGLE_CONDITIONS,
            _id: generateShortID(),
          },
        ]
        draft.config.conditions_v2[action.outerIndex].sequence_type =
          "unordered"
      } else {
        let combineOperator = "OR"
        let combineOperatorFrontend = "OR"
        if (
          draft.config.conditions_v2[action.outerIndex].conditions_v2.length > 0
        ) {
          combineOperator =
            draft.config.conditions_v2[action.outerIndex].conditions_v2[0]
              .combine_operator
          combineOperatorFrontend =
            draft.config.conditions_v2[action.outerIndex].conditions_v2[0]
              .combine_operator_frontend
        }
        draft.config.conditions_v2[action.outerIndex].conditions_v2.push({
          ...DEFAULT_NEW_SINGLE_CONDITIONS,
          combine_operator: combineOperator,
          combine_operator_frontend: combineOperatorFrontend,
          _id: generateShortID(),
        })
      }
      break
    case NEW_REMOVE_INNER_EVENT:
      draft.config.conditions_v2[action.outerIndex].conditions_v2.splice(
        action.index,
        1,
      )
      if (
        draft.config.conditions_v2[action.outerIndex].conditions_v2.length === 1
      ) {
        const tempCondition =
          draft.config.conditions_v2[action.outerIndex].conditions_v2[0]
        if (tempCondition.sequence_type) delete tempCondition.sequence_type
        tempCondition.condition_type = "single"
        tempCondition.combine_operator =
          action.outerIndex === 0
            ? "OR"
            : draft.config.conditions_v2[action.outerIndex - 1].combine_operator
        tempCondition.combine_operator_frontend =
          action.outerIndex === 0
            ? "OR"
            : draft.config.conditions_v2[action.outerIndex - 1]
                .combine_operator_frontend
        draft.config.conditions_v2.splice(action.outerIndex, 1)
        draft.config.conditions_v2.splice(action.outerIndex, 0, tempCondition)
      }
      break
    case NEW_EVENT_TYPE:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].type = action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].type = action.value
      }
      break
    case NEW_EVENT_OPERATOR:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].count_config.operator =
          action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].count_config.operator = action.value
      }
      break
    case NEW_EVENT_COUNT:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].count_config.count =
          action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].count_config.count = action.value
      }
      break
    case SET_NEW_EVENT_DETAILS:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        if (["app_event", "client_event"].includes(action.eventType)) {
          draft.config.conditions_v2[action.outerIndex].details.name =
            action.name
          draft.config.conditions_v2[action.outerIndex].screen = ""
          draft.config.conditions_v2[action.outerIndex].activity = ""
        } else {
          draft.config.conditions_v2[action.outerIndex].details.name = ""
        }
        if (action.eventType === "screen_event") {
          draft.config.conditions_v2[action.outerIndex].screen = action.name
          draft.config.conditions_v2[action.outerIndex].activity = ""
        }
        if (action.eventType === "activity_event") {
          draft.config.conditions_v2[action.outerIndex].activity = action.name
          draft.config.conditions_v2[action.outerIndex].screen = ""
        }
        draft.config.conditions_v2[action.outerIndex].event_type =
          action.eventType
      } else {
        if (["app_event", "client_event"].includes(action.eventType)) {
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].details.name = action.name
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].activity = ""
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].screen = ""
        } else {
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].details.name = ""
        }
        if (action.eventType === "screen_event") {
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].screen = action.name
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].activity = ""
        }
        if (action.eventType === "activity_event") {
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].activity = action.name
          draft.config.conditions_v2[action.outerIndex].conditions_v2[
            action.index
          ].screen = ""
        }
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].event_type = action.eventType
      }
      break
    case SET_NEW_EVENT_CATEGORY:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].event_category =
          action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].event_category = action.value
      }
      break
    case SET_NEW_INNER_COMBINE_OPERATOR:
      draft.config.conditions_v2[action.outerIndex].conditions_v2.map(
        (condition) => (condition.combine_operator = action.value),
      )
      break
    case SET_NEW_INNER_COMBINE_OPERATOR_FRONTEND:
      draft.config.conditions_v2[action.outerIndex].conditions_v2.forEach(
        (condition) => {
          condition.combine_operator_frontend = action.value
          if (action.value !== "AND_THEN") {
            condition.time_enabled = false
            condition.type = "did"
          }
        },
      )
      break
    case SET_NEW_OUTER_COMBINE_OPERATOR:
      draft.config.conditions_v2.map(
        (condition) => (condition.combine_operator = action.value),
      )
      break
    case SET_NEW_OUTER_COMBINE_OPERATOR_FRONTEND:
      draft.config.conditions_v2.forEach((condition) => {
        condition.combine_operator_frontend = action.value
        if (action.value !== "AND_THEN") {
          condition.time_enabled = false
          condition.type = "did"
        }
      })
      break
    case ADD_NEW_PROPERTY:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].details.additional_info[
          action.value
        ] = {
          op: "GT",
          val: "",
          t: "",
        }
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info[action.value] = {
          op: "GT",
          val: "",
          t: "",
        }
      }
      break
    case NEW_DELETE_ADDITIONAL_INFO:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].details.additional_info =
          {}
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info = {}
      }
      break
    case NEW_DELETE_PROPERTY:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        delete draft.config.conditions_v2[action.outerIndex].details
          .additional_info[action.value]
      } else {
        delete draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info[action.value]
      }
      break
    case ADD_NEW_DATATYPE:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].details.additional_info[
          action.currentKey
        ].t = action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info[action.currentKey].t = action.value
      }
      break
    case ADD_NEW_OPERATOR:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].details.additional_info[
          action.currentKey
        ].op = action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info[action.currentKey].op = action.value
      }
      break
    case ADD_NEW_PROPERTY_VALUE:
      if (
        draft.config.conditions_v2[action.outerIndex].condition_type ===
        "single"
      ) {
        draft.config.conditions_v2[action.outerIndex].details.additional_info[
          action.currentKey
        ].val = action.value
      } else {
        draft.config.conditions_v2[action.outerIndex].conditions_v2[
          action.index
        ].details.additional_info[action.currentKey].val = action.value
      }
      break
    case SET_NEW_ORDER_SEQUENCE:
      draft.config.conditions_v2[action.outerIndex].sequence_type = action.value
      break
    case SET_NEW_ENABLE_TIME_OUTER:
      draft.config.conditions_v2[action.outerIndex].time_enabled = action.value
      break
    case SET_NEW_ENABLE_TIME_INNER:
      draft.config.conditions_v2[action.outerIndex].conditions_v2[
        action.index
      ].time_enabled = action.value
      break
    case SET_NEW_TIME_OUTER:
      draft.config.conditions_v2[action.outerIndex].time[action.key] =
        action.value
      break
    case SET_NEW_TIME_INNER:
      draft.config.conditions_v2[action.outerIndex].conditions_v2[
        action.index
      ].time[action.key] = action.value
      break
    case SET_NEW_ORDER_SEQUENCE_PARENT:
      draft.config.sequence_type = action.value
      break
    case SET_NEW_ENABLE_ACROSS_SESSION:
      draft.config.across_sessions = action.value
      break
    case SET_NEW_NUMBER_OF_ACROSS_SESSION:
      draft.config.no_of_sessions = Number(action.value)
      break
    case SET_UI:
      draft.config.ui = action.ui
      break
    case SET_UIS:
      draft.config.uis = action.uis.filter(
        (eachUI) => !draft.uisToDelete.includes(eachUI.index),
      )
      break
    case SET_COHORTS:
      draft.cohorts = action.cohorts
      break
    case SET_DYNAMIC_COHORTS:
      draft.dynamic_cohorts = action.dynamic_cohorts
      break
    case SET_SEGMENTS:
      draft.segments = action.segments
      break
    case SET_DEFAULT_TEST_DEVICES:
      draft.test_devices = action.devices
      break
    case SET_CLIENT_EVENTS:
      draft.client_events = action.values
      break
    case SET_SCHEDULING_INSTANCE:
      draft.config.scheduling.schedule = action.value
      break
    case ADD_NEW_PROPERTY_IN_AUDIENCE:
      draft.config.audience.attributes[action.attr_key].push({
        name: "",
        operator: "EQ",
        t: "",
        tl: "",
        value: [],
      })
      break
    case SET_AUDIENCE_ATTRIBUTE:
      attrs = draft.config.audience.attributes[action.attr_key]
      if (attrs[action.index]) {
        attrs[action.index] = action.data
      }
      break
    case SET_AUDIENCE_ATTRIBUTES_ARRAY:
      draft.config.audience.attributes[action.attr_key] = action.data
      break
    case REMOVE_AUDIENCE_ATTRIBUTE:
      attrs = draft.config.audience.attributes[action.attr_key]
      attrs = attrs.filter((_, index) => index !== action.index)
      draft.config.audience.attributes[action.attr_key] = attrs
      break
    case SET_TOGGLE_APP_VERSION_FILTER:
      draft.config.audience.hasVer = action.enabled
      if (!action.enabled) {
        draft.config.audience.ver = undefined
      } else {
        draft.config.audience.ver = { op: "EQ", val: action.value }
      }
      break
    case SET_VERSION_OPERATOR:
      draft.config.audience.ver.op = action.value
      break
    case SET_VERSION_VALUE:
      if (draft.config.meta.platform === APP_PLATFORMS.android) {
        draft.config.audience.ver.build_number = action.value
        draft.config.audience.ver.val = action.value
      } else {
        draft.config.audience.ver.val = action.value
      }
      break
    case SET_TOGGLE_SESSION_ATTRIBUTES:
      draft.config.audience.attributes.hasSes = action.enabled
      if (!action.enabled) {
        draft.config.audience.attributes.session = []
      }
      break
    case SET_TOGGLE_USER_ATTRIBUTES:
      draft.config.audience.attributes.hasUser = action.enabled
      if (!action.enabled) {
        draft.config.audience.attributes.user = []
      }
      break
    case SET_EXCLUDE_COHORT_TOGGLE:
      draft.config.overall_cfg.hasExcludeCohort = action.enabled
      draft.config.overall_cfg.exclude_cohort = {
        cohort_id: "",
      }
      break
    case SET_EXCLUDE_EVENTS_TOGGLE:
      draft.config.overall_cfg.hasEvents = action.enabled
      draft.config.overall_cfg.events = []
      break
    case SET_EXCLUDE_COHORT_ID:
      draft.config.overall_cfg.exclude_cohort.cohort_id = action.cohort_id
      break
    case SET_TARGET_ANONYMOUS_USERS_ONLY:
      if (action.enabled && draft.config.audience.exclude_anonymous) {
        draft.config.audience.exclude_anonymous =
          !draft.config.audience.exclude_anonymous
      }
      draft.config.audience.only_anonymous = action.enabled
      break
    case SET_EXCLUDE_ANONYMOUS_USERS:
      if (action.enabled && draft.config.audience.only_anonymous) {
        draft.config.audience.only_anonymous =
          !draft.config.audience.only_anonymous
      }
      draft.config.audience.exclude_anonymous = action.enabled
      break
    case SET_SELECTED_TEST_DEVICE: {
      const { value, label } = action.value
      draft.config.testing.selected = value
      draft.config.testing.selectedLabel = label
      if (
        draft.config.testing.devices &&
        !draft.config.testing.devices.includes(value)
      ) {
        draft.config.testing.devices.push(value)
      } else {
        draft.config.testing.devices = [value]
      }
      break
    }
    case SET_SNACKBAR_ERROR_MESSAGE:
      draft.snackbarMessage = action.value
      break
    case SET_EXPERIMENT:
      draft.config.meta.isExperiment = true
      draft.config.meta.category = "AB-test"
      draft.config.meta.tags =
        draft?.config?.meta?.tags?.length > 0
          ? ["A/B", ...draft.config.meta.tags]
          : ["A/B"]
      draft.config.experiment = {
        currentVariantStep: 0,
        variants: {
          A: {
            name: action.name,
            indices: action.payload || [],
            distribution: 49,
          },
          CG: {
            name: "Control Group",
            indices: [],
            distribution: 2,
          },
        },
      }
      draft.config.experiment.publishSettings = {
        CG: 3,
        time_settings: {
          start_date: moment().toISOString(),
          end_date: moment().add("days", 7).toISOString(),
          time_limits_in_day: false,
          time_limits: {
            start_time: "10:00",
            end_time: "20:30",
          },
        },
      }
      draft.config.experiment.settings = {
        isManualExperiment: true,
        expectedLoss: 0.0015,
        bestProbability: 0.95,
        targetGroup: 10,
      }
      break
    case ADD_VARIANT: {
      const variantKey = String.fromCharCode(
        Object.keys(draft.config.experiment.variants).length + 64,
      )
      draft.config.experiment.variants[variantKey] = {
        name: action.value,
        indices: [],
        distribution: 49,
      }
      break
    }
    case SET_VARIANT_PERCENTAGE:
      draft.config.experiment.variants[action.variantName].distribution =
        action.value
      break
    case SET_TARGET_PERCENTAGE:
      draft.config.experiment.settings.targetGroup = action.value
      break
    case SET_AUTO_PUBLISH_WINNER:
      draft.config.experiment.settings.isManualExperiment = action.value
      break
    case SET_CURRENT_VARIANT_STEP:
      draft.config.experiment.currentVariantStep = action.value
      break
    case DELETE_VARIANT:
      draft.uisToDelete.push(...action.uiIndicesToDelete)
      draft.config.uis = draft.config.uis.filter(
        (eachUI) => !action.uiIndicesToDelete.includes(eachUI.index),
      )
      delete draft.config.experiment.variants[action.key]
      draft.config.experiment.currentVariantStep =
        action.value - 1 >= 0 ? action.value - 1 : 0

      if (
        Object.keys(draft.config.experiment.variants).filter(
          (key) => key !== "CG",
        ).length === 0
      ) {
        draft.config.meta.isExperiment = false
        draft.config.meta.tags = draft.config.meta.tags.filter(
          (eachTag) => eachTag !== "A/B",
        )
        draft.config.meta.category && delete draft.config.meta.category
      } else {
        Object.keys(draft.config.experiment.variants)
          .filter((key) => key !== "CG")
          .sort()
          .forEach((variantKey, index) => {
            if (String.fromCharCode(index + 65) !== variantKey) {
              draft.config.experiment.variants[
                String.fromCharCode(index + 65)
              ] = draft.config.experiment.variants[variantKey]
              delete draft.config.experiment.variants[variantKey]
            }
          })
      }
      break
    case ADD_STEP_TO_VARIANT:
      draft.config.experiment.variants[action.step].indices.push(action.index)
      break
    case SET_SELECTED_TEST_VARIANT:
      draft.config.testing.selectedVariant = action.value
      break
    case DELETE_STEP_FROM_VARIANT: {
      const index = draft.config.experiment.variants[
        action.currentVariantKey
      ].indices.indexOf(action.index)
      if (index > -1) {
        draft.config.experiment.variants[
          action.currentVariantKey
        ].indices.splice(index, 1)
      }
      break
    }
    case ON_COPY_STEP_VARIANT:
      draft.config.experiment.variants[action.currentVariantKey].indices.push(
        action.index,
      )
      break
    case SET_PUBLISH_START_DATE:
      draft.config.experiment.publishSettings.time_settings.start_date =
        action.date_time
      break
    case SET_PUBLISH_END_DATE:
      draft.config.experiment.publishSettings.time_settings.end_date =
        action.date_time
      break
    case SET_PUBLISH_SHOW_AT_SPECIFIC_TIME:
      draft.config.experiment.publishSettings.time_settings.time_limits_in_day =
        action.enabled
      break
    case SET_PUBLISH_TIME_LIMITS_START:
      draft.config.experiment.publishSettings.time_settings.time_limits.start_time =
        action.start_hour_min
      break
    case SET_PUBLISH_TIME_LIMITS_END:
      draft.config.experiment.publishSettings.time_settings.time_limits.end_time =
        action.end_hour_min
      break
    case SET_CAMPAIGN_PUBLISH_CG:
      if (action.isExperiment)
        draft.config.experiment.publishSettings.CG = action.value
      else draft.config.cg_percentage = action.value
      break
    case SET_SURVEY_DELAY:
      draft.config.ui.delay = action.value
      break
    default:
      return draft
  }
})
